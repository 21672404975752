<template>
  <v-row align="center" justify="center">
    <v-col cols="12" lg="8" md="12">
      <v-card>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title v-if="stepperSteps === 1">
            <v-toolbar-title v-if="$store.getters.twofa.verified === false">
              {{ $t("step1Title") }}
            </v-toolbar-title>
            <v-toolbar-title v-else>
              {{ $t("step1TitleActive") }}
            </v-toolbar-title>
          </v-toolbar-title>
          <v-toolbar-title v-if="stepperSteps === 2">
            {{ $t("step2Title") }}
          </v-toolbar-title>
          <v-toolbar-title v-if="stepperSteps === 3">
            {{ step3Title }}
          </v-toolbar-title>
          <v-spacer />
          <wiki slug="2fa" />
        </v-toolbar>

        <v-stepper v-model="stepperSteps" class="elevation-0">
          <v-stepper-items>
            <!-- ###################### About Slide ###################### -->
            <v-stepper-content
              step="1"
              v-if="
                !(
                  $store.getters.twofa.enabled && $store.getters.twofa.confirmed
                )
              "
            >
              <v-card-title>{{ $t("Two Factor Authenticator") }}</v-card-title>
              <v-card-text>
                <p>{{ $t("Description1") }}</p>
                <p>{{ $t("Description2") }}</p>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="
                    $store.getters.twofa.enabled === true &&
                    $store.getters.twofa.confirmed === false
                  "
                  text
                  @click="$router.push({ name: 'login' })"
                >
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="primary" @click="stepperSteps = 2" text>
                  {{ $t("next") }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <!-- If 2FA is already active -->
            <v-stepper-content step="1" v-else>
              <v-alert type="success">
                {{ $t("2faActive") }}
              </v-alert>
              <v-card-text>
                <p>{{ $t("DescriptionActivated") }}</p>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="
                    $store.getters.twofa.enabled === true &&
                    $store.getters.twofa.confirmed === false
                  "
                  text
                  @click="$router.push({ name: 'login' })"
                >
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="primary" @click="stepperSteps = 3" text>
                  {{ $t("next") }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <!-- ###################### Usage Slide ###################### -->
            <v-stepper-content
              step="2"
              v-if="$store.getters.twofa.verified === false"
            >
              <v-card-title> 1. {{ $t("Usage1") }} </v-card-title>

              <v-card-actions>
                <v-btn
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_blank"
                  text
                >
                  <v-icon large>mdi-android</v-icon>
                  <span>{{ $t("google-authenticator-android") }}</span>
                </v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn
                  href="https://apps.apple.com/de/app/google-authenticator/id388497605"
                  target="_blank"
                  text
                >
                  <v-icon large>mdi-apple</v-icon>
                  <span>{{ $t("google-authenticator-apple") }}</span>
                </v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn
                  href="https://winauth.github.io/winauth/download.html"
                  target="_blank"
                  text
                >
                  <v-icon large>mdi-microsoft-windows</v-icon>
                  <span>{{ $t("winauth-windows") }}</span>
                </v-btn>
              </v-card-actions>

              <v-card-title> 2. {{ $t("Usage2") }} </v-card-title>

              <v-card-title> 3. {{ $t("Usage3") }} </v-card-title>
              <v-alert type="error">
                {{ $t("LogoutWarning") }}
              </v-alert>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="stepperSteps = 1">{{ $t("back") }}</v-btn>
                <v-btn color="primary" @click="stepperSteps = 3" text>
                  {{ $t("next") }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <!-- ############### Activate/Deactivate Slide ############### -->
            <v-stepper-content step="3">
              <v-col v-if="activated === false">
                <v-text-field
                  v-model="secret"
                  readonly
                  outlined
                  append-icon="mdi-content-copy"
                  @click="copy"
                  @click:append="copy"
                  :label="$t('KeyDescription')"
                  id="secretTextFieldforCopy"
                />
              </v-col>

              <v-row v-if="activated === false">
                <v-col class="d-flex justify-center">
                  <img :src="svg" class="white" />
                </v-col>
              </v-row>

              <v-row>
                <v-spacer />
                <v-col>
                  <validation-observer
                    ref="obs"
                    v-slot="{ errors, invalid, validated, classes }"
                  >
                    <v-form @submit="submitToken" onSubmit="return false;">
                      <validation-provider
                        vid="token"
                        :name="$t('token')"
                        rules="required|digits:6"
                        v-slot="{ errors, valid }"
                      >
                        <v-otp-input
                          length="6"
                          type="number"
                          id="token"
                          v-model="token"
                          :label="$t('token')"
                          name="token"
                          :error-messages="errors"
                          :success="(submitOnValid = valid)"
                          :disabled="$store.getters.isStaff && activated"
                        />
                      </validation-provider>
                    </v-form>
                  </validation-observer>
                </v-col>
                <v-spacer />
              </v-row>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="
                    $store.getters.twofa.enabled === true &&
                    $store.getters.twofa.confirmed === false
                  "
                  text
                  @click="stepperSteps = 2"
                >
                  {{ $t("back") }}
                </v-btn>
                <v-btn v-else text @click="stepperSteps = 1">
                  {{ $t("back") }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="submitToken"
                  :loading="loading"
                  :disabled="
                    !$store.getters.isStaff &&
                    (token == null || token.length < 6)
                  "
                  :class="{ staff: $store.getters.isStaff && activated }"
                  text
                >
                  {{ $t("confirm") }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import copyToClipboard from "@/utils/mixins/copyToClipboard";
import showErrors from "@/utils/mixins/showErrors";
import Wiki from "@/components/basics/Wiki";

export default {
  mixins: [copyToClipboard, showErrors],
  components: {
    Wiki,
  },
  props: {
    ignoreSessionCheck: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    stepperSteps: 1,
    secret: "",
    activated: false,
    token: "",
    submitOnValid: false,
    svg: "",
  }),
  computed: {
    step3Title() {
      if (this.activated)
        return this.$t("step3DeactivateTitle") + this.$store.getters.name;
      else return this.$t("step3ActivateTitle") + this.$store.getters.name;
    },
  },
  watch: {
    ignoreSessionCheck: {
      immediate: true,
      handler(ignoreSessionCheck) {
        if (!this.$store.getters.isReady) {
          if (ignoreSessionCheck == null || ignoreSessionCheck === false) {
            console.log(">>> 2FA settings: not ready -> redirect to login");
            this.$router.push({
              name: "login",
              params: { next: this.$route.fullPath },
            });
          }
        }
      },
    },
    stepperSteps() {
      if (this.stepperSteps === 3) {
        this.load2FA();
      }
    },
  },
  methods: {
    clear() {
      this.token = null;
    },
    copy() {
      this.copyToClipboard(
        "secretTextFieldforCopy",
        this.$t("CopyConfirmation")
      );
    },
    load2FA() {
      this.token = "";
      this.secret = "";
      this.activated = this.$store.getters.twofa.confirmed;
      if (this.activated) return;

      this.$http.get("login").then((response) => {
        this.secret = response.data.secret;
        this.svg = response.data.qrcode;
      });
    },
    submitToken() {
      var that = this;
      if (this.loading == true) return;
      this.loading = true;
      var params = {
        token: this.token || null,
        email:
          this.$store.getters.isPerson && this.activated
            ? this.$store.getters.email
            : null,
      };
      this.$http
        .put("login/" + (this.activated ? "signoff" : "verify"), params, {
          disableDefaultErrorHandler: true,
        })
        .then((response) => {
          that.$store.commit("setTwoFactor", response.data);
          if (that.$store.getters.twofa.confirmed) {
            that.$snotify.success(that.$t("ConfiramtionMessageActivated"));
          } else {
            that.$snotify.success(that.$t("ConfiramtionMessageDeactivated"));
          }
          console.log("sign on off");
          that.$http
            .post("logout")
            .catch(function () {})
            .finally(function () {
              that.$store.commit("resetSession");
              that.$router.push({ name: "login" });
            });
        })
        .catch(function (error) {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Two Factor Authenticator": "Two Factor Authenticator",
    "step1Title": "About",
    "step1TitleActive": "Status",
    "step2Title": "Usage",
    "step3DeactivateTitle": "Deactivate for ",
    "step3ActivateTitle": "Activate for ",
    "next": "Continue",
    "back": "Back",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "Description1" : "With Two-Factor Authentication you can secure your account against password highjacking, password guessing and brute force attacks. This portal uses RFC 6238 onetime passwords to secure your account.",
    "Description2" : "To use this feature you need an application on your computer or phone which can generate RFC 6238 conform time based ontime passwords. There are several mobile apps available which implement this. One of the most popular is Google Authenticator which can be found in Apple Store and Google PlayStore. Alternatively WinAuth for Windows.",
    "DescriptionActivated" : "You have Two-Factor Authentication enabled. If you wish to disable it continue to the next step.",
    "Usage1" : "Download App",
    "Usage2" : "Scan QR Code or enter key manually",
    "Usage3" : "Confirm",
    "LogoutWarning": "You will be logged out!",
    "KeyDescription" : "Key for manual registration",
    "ConfirmationText" : "Confirm Token to ",
    "token": "Token",
    "CopyConfirmation" : "Secret copied to clipboard",
    "ConfiramtionMessageActivated" : "Two-Factor activated",
    "ConfiramtionMessageDeactivated" : "Two-Factor deactivated",
    "2faActive" : "Two-Factor Authentication is already enabled.",
    "google-authenticator-android" : "Google Authenticator for Android",
    "google-authenticator-apple" : "Google Authenticator for Apple",
    "winauth-windows" : "WinAuth for Windows"
  },
  "de": {
    "Two Factor Authenticator": "Zwei-Faktor-Authentifizierung",
    "step1Title": "Über",
    "step1TitleActive": "Status",
    "step2Title": "Benutzung",
    "step3DeactivateTitle": "Deaktivieren für ",
    "step3ActivateTitle": "Aktivieren für ",
    "next": "Weiter",
    "back": "Zurück",
    "cancel": "Abbrechen",
    "confirm": "Bestätigen",
    "Description1" : "Mit Zwei-Faktor-Authentifizierung können Sie ihren Zugang gegen Passwort Diebstahl und Passwort raten schützen. Dieses Portal nutzt Einmal Passwörter nach RFC 6238 um Ihren Zugang zu schützen.",
    "Description2" : "Um diese Funktion nutzen zu können, benötigen Sie eine Applikation auf Ihrem Computer oder Smartphone welche Einmalpasswörter nach RFC 6238 unterstützt. Es existieren diverse solcher Applikationen. Die bekannteste darunter ist Google Authenticator, welche Sie im Apple Store oder Google PlayStore finden. Unter Windows gibt es WinAuth als Alternative.",
    "DescriptionActivated" : "Sie haben die Zwei-Faktor-Authentifizierung bereits aktiviert. Falls sie wünschen diese zu deaktivieren folgen sie dem nächstem Schritt.",
    "Usage1" : "App Runterladen",
    "Usage2" : "QR Code scannen oder Schlüssel manuell übertragen",
    "Usage3" : "Bestätigen",
    "LogoutWarning": "Sie werden nach der Bestätigung ausgeloggt!",
    "KeyDescription" : "Schlüssel für die manuelle Registrierung",
    "ConfirmationText" : "Bestätige Token zum ",
    "token": "Token",
    "CopyConfirmation" : "Schlüssel in die Zwischenablage kopiert",
    "ConfiramtionMessageActivated" : "Zwei-Faktor aktiviert",
    "ConfiramtionMessageDeactivated" : "Zwei-Faktor deaktiviert",
    "2faActive": "Zwei-Faktor-Authentifizierung ist bereits Aktiv.",
    "google-authenticator-android" : "Google Authenticator für Android",
    "google-authenticator-apple" : "Google Authenticator für Apple",
    "winauth-windows" : "WinAuth für Windows"
  }
}
</i18n>
